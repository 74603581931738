<template>
  <div class="mt-2 table-responsive-sm">
    <table class="table">
      <thead>
        <tr>
          <th
            scope="col"
            class="p-2 bt-none bb-none font-weight-normal"
            :class="dataSport.textClass"
          >
            {{ $t("other.team") }}
          </th>
          <th
            scope="col"
            class="p-2 bt-none bb-none font-weight-normal"
            :class="dataSport.textClass"
          >
            {{ $t("other.player") }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="team in tp" :key="team.ID">
          <td class="align-middle p-2">
            <div class="d-flex align-items-center">
              <div class="pr-2 pr-lg-3">
                <img
                  :src="team.filetime ? ($url+'upload/images/teams/'+ team.id_team_sl +'/100x100.png?v='+ team.filetime) : '/img/icons/stub.png'"
                  width="32"
                  height="32"
                  class="rounded-sm"
                />
              </div>
              <div class="align-self-center text-white">
                <div class="small" v-text="$i18n.locale === 'ru' ? team.team_title : team.team_title_eng"/>
              </div>
            </div>
          </td>
          <td class="align-middle p-2">
            <div class="d-flex align-items-center">
              <span class="small text-white-50" v-if="$i18n.locale === 'ru'">
                {{ team.player_1_title }}, {{ team.player_2_title }}, {{ team.player_3_title }}
              </span>
              <span class="small text-white-50" v-else>
                {{ team.player_2_title_eng }}, {{ team.player_2_title_eng }}, {{ team.player_3_title_eng }}
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "TournamentParticipants",
  props: ["tp", "dataSport"]
};
</script>

<style scoped></style>
